<template>
  <div id="userpage">
    <top-nav-comp></top-nav-comp>
    <router-view />
    <!-- Initiate Routing Child components -->
    <footer-comp></footer-comp>
  </div>
</template>

<script>
import TopNavComp from "./../../containers/TopNav.vue";
import FooterComp from "./../../containers/Footer.vue";

export default {
  components: {
    TopNavComp,
    FooterComp,
  },
};
</script>

<style lang="scss">
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

#userpage {
  // background-color: $gray-dark-05;
  background: url("./../../assets/img/bg-all.jpg");
  background-size: cover;

  .header-box {
    width: 100%;
    height: 285px;
    padding: 15px;
    border-radius: 7px;
    // background-color: $green;
    background: conic-gradient(
      from 259.82deg at 105.81% 0%,
      #4ca022 -0.34deg,
      #689520 39.74deg,
      #3a7c18 183.73deg,
      #097958 306.97deg,
      #4ca022 359.66deg,
      #689520 399.74deg
    );
    color: $white;

    &.test-bg {
      background: linear-gradient(
          84.94deg,
          #373b44 32.34%,
          rgba(55, 59, 68, 0) 129.79%
        ),
        url("./../../assets/img/pen01.jpg");
      background-size: cover;

      &.pass-test {
        background: linear-gradient(
            72.18deg,
            #ffd600 29.54%,
            rgba(255, 153, 0, 0) 222.39%
          ),
          url("./../../assets/img/pen01.jpg");
        background-size: cover;
        color: #000;
      }
    }

    .box-left {
      padding: 30px 15px;
    }

    .box-right {
      padding: 10px 15px;
      margin-top: 15px;
      color: $green-light-03;
      border-radius: 7px;
      text-align: center;
      border: 3px solid $gray-light-07;
      // max-width: 180px;
      height: 80px;

      h1 {
        margin-bottom: 0px;
      }

      &.no-border {
        border: unset;
      }
    }

    .card {
      color: $black-02;
      border-radius: 10px;

      .card-body {
        h2 {
          color: $gray-regular-02;
        }
      }
    }
  }

  .contents {
    .nav-tabs {
      .nav-item {
        .nav-link {
          color: $gray-reqular !important;

          &.active {
            border: unset;
            border-bottom: 1px solid $green;
            color: $green !important;
          }
        }
      }
    }

    .nav-contents {
      .course-box {
        height: 130px;
        border-radius: 6px;
        border: 1px solid $gray-dark-03;
        background-color: $gray-dark-03;
        color: $white;
        cursor: pointer;
        padding: 10px;

        &.course-box-blue {
          background-color:#309CB4;
        }

        .span-click {
          font-weight: 100;
          font-size: 14px;
          color: $gray-light-06;
        }

        .box-featured-img {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0px;
          margin-left: -10px;
          border: 1px solid #354054;
          border-radius: 5px;
          opacity: 0.3;
        }

        .course-box-inner {
          height: 100%;
          border: 1px solid rgba(255, 255, 255, 0.4);
          border-radius: 5px;
        }

        @media (max-width: 576px) { 
          height: 200px;
        }
      }

      .topic-box {
        height: 140px;
        border-radius: 6px;
        border: 1px solid $gray-dark-03;
        background-color: $gray-dark-03;
        color: $white;
        cursor: pointer;
        padding: 10px;

        &.topic-box-blue {
          background-color:#309CB4;
        }

        .span-click {
          font-weight: 100;
          font-size: 14px;
          color: $gray-light-06;
        }

        .box-featured-img {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0px;
          margin-left: -10px;
          border: 1px solid #354054;
          border-radius: 5px;
          opacity: 0.3;
        }

        .topic-box-inner {
          height: 100%;
          border: 1px solid rgba(255, 255, 255, 0.4);
          border-radius: 5px;

          .title {
            span {
              color: $gray-light-06;
            }
          }

          .vr-link-badge {
            position: absolute;
            top: -17px;
            right: -29px;
            background-color: #47485e;
            border: 1px solid #7e8590;
            padding: 8px 29px 8px 15px;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 3px;

            a {
              text-decoration: unset;
            }

            span {
              @media (max-width: 500px) { 
                font-size: 11px;
              }
            }
          }
        }

        @media (max-width: 576px) { 
          height: 230px;
        }
      }
    }
  }

  .container-box {
    padding: 10px;
    border-radius: 5px;
    background-color: $white;

    &.box-border {
      border: 1px solid $gray-light-03;
    }

    .box-sider {
      height: calc(100vh - 338px);
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .box-contents {
      padding: 15px;
      height: screen;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .box-contents-html {
        text-align: justify;
        text-justify: inter-word;

        img {
          width: 100%;
          padding: 10px 0px;
        }

        iframe {
          width: 100%;
          padding: 10px 0px;
        }
      }

      .box-contents-footer {
        height: 100px;
      }
    }
  }

  .breadcrumb {
    .breadcrumb-item {
      a {
        color: unset;
        text-decoration: unset;
      }
    }
  }

  .vm--modal {
    padding: 25px 25px;
  }
}

.swal2-container {
  button {
    margin-right: 10px;
  }
}
</style>